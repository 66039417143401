<div class="container-fluid" style="display: flex; background-color:#0f4c75;background-image: url('https://postgradofces.com/assets/img/bg-ofertas-academica.png');height: auto;background-size:cover; background-repeat:repeat; background-position: center;">
    <div class="container">
        <div class="row my-5">
            <div class="col-md-4 mt-5">

                <div class="col-md rounded-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Administración</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white bg-transparent table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/maestria-administracion-empresas" class="fs-6">Maestría en Administración de Empresas Cooperativas</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/maestria-en-alta-gerencia" class="fs-6">Maestría en Alta Gerencia</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/maestria-direccion-estrategica" class="fs-6">Maestría en Dirección Estratégica y Gestión de la Innovación</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/gerencia-financiera" class="fs-6">Maestría en Gerencia Financiera</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/recursos-humanos" class="fs-6">Maestría en Gestión de Recursos Humanos</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/gestion-publica" class="fs-6">Maestría en Gestión Pública</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/seguros-fianzas" class="fs-6">Maestría en Seguros y Fianzas</a></td>
                          </tr>
                          <tr>
                            <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                            </svg></th>
                            <td><a routerLink="/gestion-logistica" class="fs-6">Maestría en Gestión Logística de las Aduanas y Puertos</a></td>
                          </tr>
                          <tr>
                            <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                            </svg></th>
                            <td><a routerLink="/gestion-finanzas-cooporativa" class="fs-6">Maestría Gestión en Finanzas Coorporativas</a></td>
                          </tr>
                        </tbody>
                    </table>
                </div>
            </div> 

            <div class="col-md-4 mt-5">
                <div class="col-md rounded-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Economia</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/finanzas-mercado" class="fs-6">Maestría en Finanzas y Mercado de Capitales</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/negocios-relaciones-economicas" class="fs-6">Maestría en Negocios y Relaciones Económicas Internacionales</a></td>
                          </tr>
                          <tr>
                            <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                            </svg></th>
                            <td><a routerLink="/master-globalizacion" class="fs-6">Maestría en Master Globalización</a></td>
                          </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md rounded-3 mt-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Contabilidad</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/auditoria-interna" class="fs-6">Maestría en Auditoria Interna</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/contabilidad-gubernamental" class="fs-6">Maestría en Contabilidad Gubernamental</a></td>
                          </tr>
                          <tr>
                              <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                  <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                              </svg></th>
                              <td><a routerLink="/contabilidad-tributaria" class="fs-6">Maestría en Contabilidad Tributaria</a></td>
                          </tr>
                        </tbody>
                    </table>



                </div>
            </div>
            
            <div class="col-md-4 mt-5">
                <div class="col-md rounded-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Mercadotecnia</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                                </svg></th>
                                <td><a routerLink="/marketing-estrategico" class="fs-6">Maestría en Marketing Estrategico</a></td>
                            </tr>
                            <tr>
                                <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                                </svg></th>
                                <td><a routerLink="/estrategia-cibermaketing" class="fs-6">Maestría en Estrategia de Cibermarketing</a></td>
                            </tr>
                        </tbody>
                    </table>


                </div>

                <div class="col-md rounded-3 mt-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Sociología y Trabajo Social</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                                </svg></th>
                                <td><a routerLink="/sociologia-educacion" class="fs-6">Maestría en Sociología de la Educación</a></td>
                            </tr>
                            <tr>
                                <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                                    <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                                </svg></th>
                                <td><a routerLink="/trabajo-social" class="fs-6">Maestría en Trabajo Social</a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md rounded-3 mt-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Estadísticas</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                    <tr>
                        <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                            <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                        </svg></th>
                        <td><a routerLink="/estadistica-especializada" class="fs-6">Maestría en Estadística Especializada</a></td>
                    </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-md rounded-3 mt-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold">Escuela de Turismo y Hotelería</p>
                </div>
                <div class="col-md border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless table-hover">
                        <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                    <tr>
                        <th scope="row"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" style="background-color:#000;color:#e9611b;" class="bi bi-arrow-right-square-fill" viewBox="0 0 16 16">
                            <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1"/>
                        </svg></th>
                        <td><a routerLink="/relacion-de-los-destinos" class="fs-6">Maestría en Gestión de los Destinos Turísticos Sostenibles</a></td>
                    </tr>
                        </tbody>
                    </table>
                </div>
                
            </div>
            

        </div>
    </div>
</div>

