import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlanEstudiosComponent } from './components/plan-estudios/plan-estudios.component';
import { IndexComponent } from './components/index/index.component';
import { AdmisionesComponent } from './components/admisiones/admisiones.component';
import { ServiciosComponent } from './components/servicios/servicios.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { ComprobanteComponent } from './components/comprobante/comprobante.component';
import { OfertasComponent } from './components/ofertas/ofertas.component';
import { EstudiantesComponent } from './components/estudiantes/estudiantes.component';
import { PublicacionComponent } from './components/publicacion/publicacion.component';
import { EducacionContinuadaComponent } from './components/educacion-continuada/educacion-continuada.component';
import { AdminGuard } from './guards/admin.guard';
import { CoorGuard } from './guards/coor.guard';
/** Maestrias */
import { AltaGerenciaComponent } from './maestrias/alta-gerencia/alta-gerencia.component';
import { MaestriaAdministracionEmpresasComponent } from './maestrias/maestria-administracion-empresas/maestria-administracion-empresas.component';
import { MaestriaDireccionEstrategicaComponent } from './maestrias/maestria-direccion-estrategica/maestria-direccion-estrategica.component';
import { GerenciaFinancieraComponent } from './/maestrias/gerencia-financiera/gerencia-financiera.component';
import { RecursosHumanosComponent } from './maestrias/recursos-humanos/recursos-humanos.component';
import { GestionPublicaComponent } from './maestrias/gestion-publica/gestion-publica.component';
import { SegurosFianzasComponent } from './maestrias/seguros-fianzas/seguros-fianzas.component';
import { GestionLogisticaComponent } from './maestrias/gestion-logistica/gestion-logistica.component';
import { FinanzasMercadoComponent } from './maestrias/finanzas-mercado/finanzas-mercado.component';
import { NegociosRelacionesEconomicasComponent } from './maestrias/negocios-relaciones-economicas/negocios-relaciones-economicas.component';
import { MasterGlobalizacionComponent } from './maestrias/master-globalizacion/master-globalizacion.component';
import { AuditoriaInternaComponent } from './maestrias/auditoria-interna/auditoria-interna.component';
import { ContabilidadGubernamentalComponent } from './maestrias/contabilidad-gubernamental/contabilidad-gubernamental.component';
import { ContabilidadTributariaComponent } from './maestrias/contabilidad-tributaria/contabilidad-tributaria.component';
import { MarketingEstrategicoComponent } from './maestrias/marketing-estrategico/marketing-estrategico.component';
import { EstrategiaCibermarketingComponent } from './maestrias/estrategia-cibermarketing/estrategia-cibermarketing.component';
import { SociologiaEducacionComponent } from './maestrias/sociologia-educacion/sociologia-educacion.component';
import { TrabajoSocialComponent } from './maestrias/trabajo-social/trabajo-social.component';
import { EstadisticaEspecializadaComponent } from './maestrias/estadistica-especializada/estadistica-especializada.component';
import { GestionFinanzasCoorporativaComponent } from './maestrias/gestion-finanzas-coorporativa/gestion-finanzas-coorporativa.component';
import { RelacionDeLosDestinosComponent } from './maestrias/relacion-de-los-destinos/relacion-de-los-destinos.component';
import { AdministracionProyectosComponent } from './maestrias/administracion-proyectos/administracion-proyectos.component';
import { DesarrolloLiderazgoComponent } from './maestrias/desarrollo-liderazgo/desarrollo-liderazgo.component';
import { GestionAnalisisEstadisticasComponent } from './maestrias/gestion-analisis-estadisticas/gestion-analisis-estadisticas.component';
import { SociologiaUrbanaComponent } from './maestrias/sociologia-urbana/sociologia-urbana.component';

/** Publicaciones Links */
import { IniseComponent } from './publicaciones/inise/inise.component';

/** Dashboard Admin*/

import { AdminBoardComponent } from './dashboard/admin-board/admin-board.component';


/** Dashboard Coor*/
import { CoorBoardComponent } from './dashboard/coor-board/coor-board.component';
import { CoorStudentsComponent } from './coordinador/coor-students/coor-students.component';
import { CoorEvalComponent } from './coordinador/coor-eval/coor-eval.component';
 import { CoorRegistroComponent } from './coordinador/coor-registro/coor-registro.component';


import { AudiBoardComponent } from './auditorias/audi-board/audi-board.component';
// import { NotificationComponent } from './administrador/notification/notification.component';
import { EquipoComponent } from './administrador/equipo/equipo.component';
import { EvaluationComponent } from './administrador/evaluation/evaluation.component';
import { LogoutComponent } from './components/logout/logout.component';
import { ProgramaComponent } from './administrador/programa/programa.component';
import { ResultAdmisionComponent } from './administrador/result-admision/result-admision.component';
import { ResultServiceComponent } from './administrador/result-service/result-service.component';
import { ResultStudentsComponent } from './administrador/result-students/result-students.component';
import { ResultNotificationComponent } from './administrador/result-notification/result-notification.component';
import { ResultRecordComponent } from './administrador/result-record/result-record.component';
import { ResultCoorComponent } from './administrador/result-coor/result-coor.component';
import { InscripcionesComponent } from './administrador/inscripciones/inscripciones.component';

/** Dashboard User */
import { UserBoardComponent } from './dashboard/user-board/user-board.component';
import { UserEvaluacionComponent } from './usuarios/user-evaluacion/user-evaluacion.component';
import { UserCalificacionComponent } from './usuarios/user-calificacion/user-calificacion.component';



const routes: Routes = [
//   { 
// 		path:'',
// 		redirectTo:'/',
// 	 	pathMatch: 'full'
// 	}
// 	,
	{
		path:'',
		component: IndexComponent	
	},
	{
		path:'user',
		component: UserBoardComponent	
	},
	{
		path:'coor',
		component: CoorBoardComponent, canActivate: [CoorGuard] 	
	},
	{
		path:'coor/estudiantes',
		component: CoorStudentsComponent, canActivate: [CoorGuard] 	
	},
	{
		path:'coor/evaluacion',
		component: CoorEvalComponent, canActivate: [CoorGuard] 	
	},
	
	 {
	 	path:'coor/registro',
	 	component: CoorRegistroComponent, canActivate: [CoorGuard] 	
	},

	{
		path:'admin',
		component: AdminBoardComponent	, canActivate: [AdminGuard] 
	},
	{
		path:'auditor',
		component: AudiBoardComponent	,canActivate: [AdminGuard] 
	},
	{
		path: 'notificacion',
		component: ResultNotificationComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'equipo',
		component: EquipoComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'programas',
		component: ProgramaComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultsadmision',
		component: ResultAdmisionComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultsservice',
		component: ResultServiceComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultstudents',
		component: ResultStudentsComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultsrecord',
		component: ResultRecordComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultscoor',
		component: ResultCoorComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'admin/resultinscripcion',
		component: InscripcionesComponent,canActivate: [AdminGuard] 
	},
	
	{
		path: 'evaluacion',
		component: EvaluationComponent,canActivate: [AdminGuard] 
	},
	{
		path: 'plan-estudios',
		component: PlanEstudiosComponent
	},
	{
		path: 'admisiones',
		component: AdmisionesComponent
	},
	{
		path: 'servicios',
		component: ServiciosComponent
	},
	{
		path: 'publicaciones',
		component: PublicacionComponent
	},
	{
		path: 'educacion-continuada',
		component: EducacionContinuadaComponent
	},
	
	{
		path: 'comprobante',
		component: ComprobanteComponent
	},
	{
		path: 'ofertas-academica',
		component: OfertasComponent
	},
	{
		path: 'estudiantes',
		component: EstudiantesComponent
	},
	{
		path: 'u/user-evaluacion',
		component: UserEvaluacionComponent
	},
	{
		path: 'u/user-calificacion',
		component: UserCalificacionComponent
	},

	{
		path: 'publicaciones/inise',
		component: IniseComponent
	},

	{
		path: 'maestria-en-alta-gerencia',
		component: AltaGerenciaComponent
	},
	{
		path: 'maestria-administracion-empresas',
		component: MaestriaAdministracionEmpresasComponent
	},
	{
		path: 'maestria-direccion-estrategica',
		component: MaestriaDireccionEstrategicaComponent
	},
	{
		path: 'gerencia-financiera',
		component: GerenciaFinancieraComponent
	},
	{
		path: 'recursos-humanos',
		component: RecursosHumanosComponent
	},
	{
		path: 'gestion-publica',
		component: GestionPublicaComponent
	},
	{
		path: 'seguros-fianzas',
		component: SegurosFianzasComponent
	},
	{
		path: 'gestion-logistica',
		component: GestionLogisticaComponent
	},
	{
		path: 'finanzas-mercado',
		component: FinanzasMercadoComponent
	},
	{
		path: 'negocios-relaciones-economicas',
		component: NegociosRelacionesEconomicasComponent
	},
	{
		path: 'master-globalizacion',
		component: MasterGlobalizacionComponent
	},
	{
		path: 'auditoria-interna',
		component: AuditoriaInternaComponent
	},
	{
		path: 'contabilidad-gubernamental',
		component: ContabilidadGubernamentalComponent
	},
	{
		path: 'contabilidad-tributaria',
		component: ContabilidadTributariaComponent
	},
	{
		path: 'marketing-estrategico',
		component: MarketingEstrategicoComponent
	},
	{
		path: 'estrategia-cibermaketing',
		component: EstrategiaCibermarketingComponent
	},
	{
		path: 'sociologia-educacion',
		component: SociologiaEducacionComponent
	},
	{
		path: 'trabajo-social',
		component: TrabajoSocialComponent
	},
	{
		path: 'estadistica-especializada',
		component: EstadisticaEspecializadaComponent
	},
	{
		path: 'gestion-finanzas-cooporativa',
		component: GestionFinanzasCoorporativaComponent
	},
	{
		path: 'relacion-de-los-destinos',
		component: RelacionDeLosDestinosComponent
	},
	{
		path: 'administracion-proyectos',
		component: AdministracionProyectosComponent
	},
	{
		path: 'desarrollo-liderazgo',
		component: DesarrolloLiderazgoComponent
	},
	{
		path: 'analisis-estadisticas',
		component: GestionAnalisisEstadisticasComponent
	},
	{
		path: 'sociologia-urbana',
		component: SociologiaUrbanaComponent
	},
	{
		path: 'logout',
		component: LogoutComponent
	},
	{
		path: '**',
		component: PagenotfoundComponent
	}
	
	
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
