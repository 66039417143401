<div class="container-fluid px-0 py-0 ms-auto" style="height: auto; background-color:#0f4c75;background-image: url(https://postgradofces.com/assets/img/bg-auditoria-interna.png);background-size: cover;background-repeat: no-repeat;background-position: center;">
    <div class="row me-auto">
        <div class="col-md p-3">
            <div class="row mx-5 px-3 py-3 mt-5"> 
                <div class="col-md">
                    <p class="text-xs text-white">Maestría en</p>
                    <p class="textMod textModBig text-uppercase fw-bold text-white">Auditoria Interna</p>
                    <hr class="text-white fs-1 border-3 w-25" style="color:#fff;">
                </div>
                <div class="row mx-0 px-0 mt-5">
                    <div class="row">
                        <div class="d-flex btnAlign">
                            <a href="https://postgradofces.com/assets/pdf/Maestria-en-Auditoria-Interna.pdf" class="btn" download>
                            <div class="btnMod bg-white border-0 rounded-3 px-3 py-3">
                                <span class="text-dark text-sm fw-bold text-center" style="display: block ruby;">Descargar Programa</span>
                                
                            </div>  
                            </a>
                            <a routerLink="/admisiones" class="btn">
                                <div class="btnMod bg-white border-0 rounded-3 px-3 py-3">
                                    <span class="text-dark text-sm fw-bold text-center" style="display: block ruby;">Solicitud Admisión</span>
                                    
                                </div>  
                            </a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="d-flex">
                            <a href="https://wa.link/4g5evg" class="btn text-white text-sm fw-bold">
                            <div class="bg-transparent border border-white border-1 rounded-3 px-3 py-3 d-flex">
                                
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#5bdc2c" class="bi bi-whatsapp px-1" viewBox="0 0 16 16">
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                                    </svg> WhatsApp
                                
                            </div>
                            </a>
                            
                        </div>
                    </div>
                    <div class="row">
                        <div class="bg-transparent" style="margin-left: 1rem;margin-top: 2rem;">
                            <span class="text-white text-sm fw-bold text-start ml-2">Responder mis dudas.</span>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        
        <div class="col-md-4 p-5">
            <div class="d-block justify-content-center" style="margin-top: 4rem;">
                <div class="rounded-3 mt-3 py-2" style="background-color: #e9611b;">
                    <p class="text-center text-white fs-6 fw-bold p-2">Requisitos de Ingreso:</p>
                </div>
                <div class="border-0 rounded-3 bg-white p-2 mt-3">
                    <table class="table table-white table-borderless text-center">
                        <thead>
                            <tr>
                                <th scope="col"></th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <ul>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Record Notas Legalizado MESCYT.</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Carta de Grado Legalizado MESCYT.</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Copia de Título Legalizado MESCYT</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Acta de Nacimiento Original.</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">2 Fotos 2x2.</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Fotocopia de la Cédula a color.</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Hoja de Vida (Curriculum Vitae).</p></td>

                                    </tr>
                                </li>
                                <li>
                                    <tr>
                                        <td><p class="text-start text-xs" style="text-decoration: dotted;">Certificado Médico UASD.</p></td>

                                    </tr>
                                </li>
                            </ul>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
